<template>
  <div class="exhibitionListBox">
    <div class="companyList">
      <searchHead :type="3" @search="search"></searchHead>
      <div class="title">
        <div class="logo">{{ $t("companyLogo") }}</div>
        <div class="name">{{ $t("companyName") }}</div>
        <div class="address">{{ $t("country") }}/{{ $t("city1") }}</div>
        <!-- <div class="no">{{ $t("BoothNumber") }}</div> -->
        <div class="handle"></div>
      </div>
      <div
        v-if="list.length == 0 && !loading"
        style="position: relative; padding: 200px 0"
      >
        <noDataImg />
      </div>

      <div v-loading="loading">
        <div v-for="(item, index) in list" class="companyBox" :key="index">
          <div class="companyItem">
            <div class="logo">
              <al-image
                class="itemImage"
                :src="item.logo ? item.logo : DEFAULT_LOGO"
                fit="cover"
              >
              </al-image>
            </div>
            <div class="name" @click="showCompany(item)">
              {{
                item.company_name_en | priorFormat(item.company_name_zh, LOCALE)
              }}
            </div>
            <div class="address">
              {{
                item.country
                  | countryCityFormat(item.region_en, item.region_zh, LOCALE)
              }}
            </div>
            <!-- <div class="no">{{ item.booth_no || "-" }}</div> -->
            <div
              class="handle"
              v-if="!item.show"
              @click="showUser(index, item)"
            >
              Show<i class="el-icon-caret-right"></i>
            </div>
            <div class="handle" v-else @click="hiddenUser(index)">
              Hide<i class="el-icon-caret-bottom"></i>
            </div>
          </div>
          <div
            class="userList"
            v-if="item.show"
            v-for="(ii, dd) in item.user_info"
            :key="dd"
          >
            <div class="userThumb" @click="showCard(ii, item)">
              <al-image
                class="itemImage"
                :src="ii.avatar ? ii.avatar : DEFAULT_AVATAR"
                fit="cover"
              ></al-image>
            </div>
            <div class="userName" @click="showCard(ii, item)">
              {{ ii.user_name_en | priorFormat(ii.user_name_zh, LOCALE) }}
            </div>
            <div class="userJob">
              {{ ii.job_title_en | priorFormat(ii.job_title_zh, LOCALE) }}
            </div>
            <div class="changeCard" v-if="USER_INFO.id">
              <span
                class="cursor"
                @click="changeCard(item, ii)"
                v-if="ii.user_id != USER_INFO.id"
                ><i class="el-icon-s-finance"></i>{{ $t("cardExchange") }}</span
              >
              <span v-else class="finanColor"
                ><i class="el-icon-s-finance"></i>{{ $t("cardExchange") }}</span
              >
            </div>
            <div
              class="changeCard cursor"
              @click="$router.push('/sign')"
              v-else
            >
              <i class="el-icon-s-finance"></i>{{ $t("cardExchange") }}
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <el-pagination
          background
          :current-page="start"
          @current-change="changePage"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <companyDialog
      :companyId="company_id"
      v-if="companyShow"
      @dialogClose="companyShow = false"
      ref="companyDialog"
    />
    <cardDialog></cardDialog>
  </div>
</template>

<script>
// import banner from "~exp/components/common/banner";
import searchHead from "~nbk/components/searchHead";
import cardDialog from "~nbk/components/cardDialog";
import companyDialog from "~nbk/components/companyDialog";
export default {
  components: { searchHead, cardDialog, companyDialog },
  data() {
    return {
      start: 1,
      limit: 10,
      list: [],
      total: 0,
      country_id: "",
      city_id: "",
      name: "",
      loading: false,
      booth_no: "",
      industry: "",
      company_business: "",
      shipping_line: "",
      companyShow: false,
      company_id: "",
    };
  },
  mounted() {
    // document.title = "WFA BI-LATERAL VIRTUAL MEETINGS";
    document.title = "2022 China (Ningbo) Export Cross-border E-commerce Expo";
    this.getList();
  },
  methods: {
    showCompany(item) {
      this.companyShow = true;
      this.company_id = item.company_id;
      setTimeout((res) => {
        this.$refs.companyDialog.dialogVisible = true;
      }, 0);
    },
    showCard(row, item) {
      console.log(row);
      row.company_id = item.company_id;
      this.$GLOBALEVENT.$emit("OPENUSERCard", { show: true, row });
    },
    async changeCard(item, ii) {
      if (!this.USER_INFO.company_id) {
        //用户不存在公司
        this.$router.push("/register");
        return;
      }
      if (!this.USER_INFO.company_id) {
        //用户不存在公司
        this.$router.push("/register");
        return;
      }
      if (this.USER_INFO && !this.ENROLL_INFO.is_user_enroll) {
        //用户未报名会议
        this.$router.push("/register");
        return;
      }
      let params = {
        company_id: item.company_id,
        visit_company_id: this.USER_INFO.company_id,
        visit_user_id: this.USER_INFO.id,
        followed_user_id: ii.user_id,
        follow_type: 2,
        source: this.PJSource,
      };

      let exchangeCard = await this.$store.dispatch(
        "register/exchangeCard",
        params
      );
      if (exchangeCard && exchangeCard.success) {
        this.$message.success(this.$t("exchangeCard_success"));
      }
      if (exchangeCard && exchangeCard.message == "already exist") {
        this.$message.warning(this.$t("card_exit"));
      }
    },
    async showUser(e, item) {
      this.$set(this.list[e], "show", true);
    },
    hiddenUser(e) {
      this.$set(this.list[e], "show", false);
    },
    search(e) {
      this.country_id = e.country;
      this.city_id = e.city;
      this.name = e.company;
      this.start = 1;
      this.booth_no = e.boothId;
      this.industry = e.industry;
      this.company_business = e.business_classification;
      this.shipping_line = e.major;
      this.getList();
    },
    gotoDetail(e) {
      if (!e.booth_id) return;
      this.$router.push({
        path: "/boothDetail",
        query: { company_id: e.company_id, booth_id: e.booth_id },
      });
    },
    changePage(e) {
      this.start = e;
      this.BACKPAGETOP();
      this.getList();
    },
    async getList() {
      this.loading = true;
      let parmas = {
        start: this.start - 1,
        limit: this.limit,
        meeting_id: this.MEETING_ID,
        apply_type: "4",
        country_id: this.country_id,
        city_id: this.city_id,
        name: this.name,
        booth_no: this.booth_no,
        industry: this.industry,
        company_business: this.company_business,
        shipping_line: this.shipping_line,
        is_paid: 0,
      };
      let data = await this.$store.dispatch(
        "booth/getExpoExhibitorList",
        parmas
      );
      this.loading = false;
      if (data.success) {
        data.data.forEach((element) => {
          element.show = false;
        });
        this.list = data.data;
        this.total = data.total;
      }
    },
  },
};
</script>

<style scoped lang="less">
.banner {
  width: 100%;
}
.userList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
  .userThumb {
    width: 200px;
    cursor: pointer;
  }
  .itemImage {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
  }
  .userName {
    cursor: pointer;
    width: 250px;
  }
  .userJob {
    width: 250px;
  }
  .changeCard {
    width: 180px;
    display: flex;
    align-items: center;
    color: #409eff;
    i {
      font-size: 30px;
      margin-right: 10px;
    }
    .finanColor {
      color: #81bfff;
    }
  }
}
.block {
  width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}
.exhibitionListBox {
  background: #f3f3f3;
  min-height: calc(100vh - 368px);
}
.companyList {
  margin: 0px auto;
  width: 1300px;
  padding: 30px 0;
  position: relative;
}
.companyBox {
  margin-bottom: 30px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.companyItem {
  padding: 16px 30px;
  background: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .logo {
    width: 200px;

    .itemImage {
      width: 80px;
      height: 80px;
    }
  }
  .name {
    cursor: pointer;
    // color: #409eff;
    width: 500px;
  }
  .address {
    text-align: center;
    width: 300px;
  }
  .no {
    text-align: center;
    width: 200px;
  }
}
.title {
  display: flex;
  justify-content: space-around;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 62px;
  .logo {
    width: 200px;
  }
  .name {
    width: 500px;
  }
  .address {
    width: 300px;
    text-align: center;
  }
  .no {
    width: 200px;
    text-align: center;
  }
}
.handle {
  width: 100px;
  text-align: right;
  color: #409eff;
  cursor: pointer;
}
</style>